export const routes = [
  {
    name: 'Layout',
    redirect: '/home',
    path: '/',
    component: () => import(/* webpackChunkName: "layout" */ '../layouts/Landing.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
          title: 'CareerBuddy: Connecting companies to high-quality remote talents',
          description:
            'CareerBuddy is your trusted partner for companies looking to hire high-quality remote talents. Our platform connects fast growing companies to the most rigorously vetted pool of remote talents.',
        },
      },
      {
        path: '/talents',
        name: 'Talents',
        component: () => import(/* webpackChunkName: "talents" */ '../views/Candidate.vue'),
        meta: {
          title: 'CareerBuddy: Find Your Next Job',
          description:
            'CareerBuddy is the certified platform that skilfully aligns you with exclusive job opportunities. Create a profile, showcase your work, and let companies apply to you. No applications, no cover letters, just great matches. Join CareerBuddy today and find your dream job.',
        },
      },
      {
        path: '/hire/sales-and-marketing',
        name: 'sales-marketing',
        component: () => import(/* webpackChunkName: "sales-marketing" */ '../views/hire/SalesAndMarketing.vue'),
        meta: {
          title: 'CareerBuddy: Hire Five-Star Sales and Marketing Talents',
          description:
            'CareerBuddy connects you with the top 1%, elite candidates who possess excellent sales and marketing skills. With us, you can streamline your hiring process, reduce costs, and effortlessly discover the best teammates to fulfil your sales and marketing needs.',
        },
      },

      {
        path: '/hire/finance-and-accounting',
        name: 'finance-accounting',
        component: () => import(/* webpackChunkName: "finance-and-accounting" */ '../views/hire/FinanceAndAccounting.vue'),
        meta: {
          title: 'CareerBuddy: Hire the Best Finance and Accounting Talents',
          description:
            "Transform your team with CareerBuddy's premium finance and accounting talent pool. We have an extensive network of top-tier finance and accounting professionals from Africa for your teams. Save time, money, and hassle with CareerBuddy, your trusted partner in building a skilled and diverse workforce.",
        },
      },
      {
        path: '/hire/support',
        name: 'support',
        component: () => import(/* webpackChunkName: "support" */ '../views/hire/Support.vue'),
        meta: {
          title: 'CareerBuddy: Hire Top-Notch Customer Support, Ops and Virtual Assistants',
          description:
            'Hire top African talent for customer support, ops, and VA’s. CareerBuddy helps you find, interview, and hire pre-vetted candidates from Africa who are skilled, experienced, and cost-effective. Save time and money with our fast and easy hiring process.',
        },
      },
      {
        path: '/hire/engineering-data-technology',
        name: 'engineering-data-technology',
        component: () => import(/* webpackChunkName: "engineering-data-technology" */ '../views/hire/engr.vue'),
        meta: {
          title: 'CareerBuddy: Hire Exceptional Engineering and Tech Talents',
          description:
            'CareerBuddy connects you with the top engineering, data and technology talents from Africa. You can hire faster, save money and find the best teammates for your business. Join our satisfied customers who have fuelled their business growth.',
        },
      },
      {
        path: '/employer',
        name: 'Employer',
        component: () => import(/* webpackChunkName: "employer" */ '../views/Employer.vue'),
        meta: {
          title: 'CareerBuddy: Connecting companies to high-quality remote talents',
          description:
            'CareerBuddy is your trusted partner for companies looking to hire high-quality remote talents. Our platform connects fast growing companies to the most rigorously vetted pool of remote talents.',
        },
      },
      {
        path: '/employer-branding',
        name: 'EmployerBranding',
        component: () => import(/* webpackChunkName: "employer-branding" */ '../views/landing/EmployerBranding.vue'),
        meta: {
          title: 'CareerBuddy: Employer Branding',
          description:
            'CareerBuddy is the first-choice platform for African talent. We help you create an irresistible employer branding strategy that showcases your brand’s story, culture, and values. You get access to engaging visual content, interactive digital platforms, and insightful analytics to measure your brand’s impact.',
        },
      },
      {
        path: '/promote-brand',
        name: 'PromoteBrand',
        component: () => import('@/views/PromoteBrand.vue'),
        meta: {
          title: 'Promote Your Brand - CareerBuddy',
          description:
            "Put your brand in front of the right people. Reach thousands of millennial and Gen-Z professionals across Africa through CareerBuddy's trusted media platforms.",
        },
      },
      {
        path: '/talent-placement',
        name: 'TalentPlacement',
        component: () => import(/* webpackChunkName: "talent-placement" */ '../views/landing/TalentPlacement.vue'),
        meta: {
          title: 'CareerBuddy: Hire Better, Faster, Cheaper',
          description:
            "CareerBuddy has a unique talent acquisition service that unites you with the best candidates for your company. Whether you're a CEO, HR leader, or executive, we'll help you fill your roles faster and cheaper with our proven approach. Start hiring today and join our 100+ satisfied customers.",
        },
      },
      {
        path: '/talent-marketplace',
        name: 'TalentMarketPlace',
        component: () => import(/* webpackChunkName: "talent-marketplace" */ '../views/landing/TalentMarketPlace.vue'),
        meta: {
          title: 'CareerBuddy: Talent Marketplace',
          description:
            'You can create unlimited jobs, browse qualified candidates, schedule interviews, and hire the right talent instantly. You can also find your next job, access salary data, and get career advice from experts. CareerBuddy is trusted by a 150,000+ audience to fuel business growth and help you find work.',
        },
      },
      {
        path: '/outsourcing',
        name: 'Outsourcing',
        component: () => import(/* webpackChunkName: "outsourcing" */ '../views/landing/Outsource.vue'),
        meta: {
          title: 'CareerBuddy: Outsourcing Talent',
          description:
            'CareerBuddy is the ultimate solution for outsourcing your HR needs. We connect you with vetted talents all over the world so you can focus on growing your business while we take care of the rest. Start hiring today with CareerBuddy. ',
        },
      },
      {
        path: '/embedded-recruiter',
        name: 'EmbeddedRecruiter',
        component: () => import(/* webpackChunkName: "embeded-recruiter" */ '../views/landing/EmbeddedRecruiter.vue'),
        meta: {
          title: 'CareerBuddy: Embedded Recruiter',
          description:
            'CareerBuddy is the leading African outsourcing solution, you get access to the most innovative embedded talent partners for your company. Whether you need short-term or long-term support, CareerBuddy can find you the perfect fit for your team and culture. ',
        },
      },
      {
        path: '/terms-and-privacy',
        name: 'TermsAndPrivacy',
        component: () => import(/* webpackChunkName: "terms-and-privacy" */ '../views/landing/TermsAndPrivacy.vue'),
        meta: {
          title: 'CareerBuddy: Terms of service and privacy policy',
          description:
            'CareerBuddy is your trusted partner for companies looking to hire high-quality remote talents. Our platform connects fast growing companies to the most rigorously vetted pool of remote talents.',
        },
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
        meta: {
          title: 'CareerBuddy: Connecting companies to high-quality remote talents',
          description:
            'CareerBuddy is your trusted partner for companies looking to hire high-quality remote talents. Our platform connects fast growing companies to the most rigorously vetted pool of remote talents.',
        },
      },
      {
        path: '/pricing',
        name: 'Pricing',
        component: () => import(/* webpackChunkName: "pricing" */ '../views/landing/Pricing.vue'),
        meta: {
          title: 'CareerBuddy: Pricing',
          description:
            'Get the best candidates for your job openings only with CareerBuddy. Pick the right plan for your needs and get started today for free. Trusted by 150,000+ audience to fuel business growth.',
        },
      },
      {
        path: '/jobs',
        name: 'Jobs',
        component: () => import(/* webpackChunkName: "jobseekers" */ '../views/landing/Jobs.vue'),
        meta: {
          title: 'CareerBuddy: Open Jobs For You',
          description:
            'Looking for your dream job? Browse through our job board, updated weekly, and discover compelling opportunities that match your skills and interests. Whether you want to grow in your current field or explore a new one, CareerBuddy can help you make your next career move. ',
        },
      },
      {
        path: '/all-jobs',
        name: 'AllJobs',
        component: () => import(/* webpackChunkName: "jobseekers" */ '../views/landing/AllJobs.vue'),
        meta: {
          title: 'CareerBuddy: Connecting companies to high-quality remote talents',
          description:
            'CareerBuddy is your trusted partner for companies looking to hire high-quality remote talents. Our platform connects fast growing companies to the most rigorously vetted pool of remote talents.',
        },
      },
      {
        path: '/:company_name/:id/:title',
        name: 'Jobdetails',
        component: () => import(/* webpackChunkName: "jobdetail" */ '../views/landing/Jobdetails.vue'),
        meta: { sitemap: { ignoreRoute: true } },
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/landing/About.vue'),
        meta: {
          title: 'About CareerBuddy - Your Trusted Career Partner',
          description:
            "Learn about CareerBuddy's mission to revolutionize talent acquisition and career development. Discover how we connect companies with top talent and help professionals achieve their career goals.",
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "notfound" */ '../views/landing/NotFound.vue'),
        meta: { sitemap: { ignoreRoute: true } },
      },
      {
        path: '/cv',
        name: 'CV',
        component: () => import(/* webpackChunkName: "cv" */ '../views/CVWrite.vue'),
        meta: {
          title: 'CareerBuddy: Get a Standout CV',
          description:
            "Tired of sending resumes that get ignored by employers? CareerBuddy’s team of professional writers will work with you to craft a winning resume that displays your strengths, works with recruiting software, and earns you interviews. Don't let a generic resume hold you back from achieving your career goals.",
        },
      },
      {
        path: '/blog',
        name: 'Blogs',
        component: () => import(/* webpackChunkName: "blogs" */ '../views/Blogs.vue'),
        meta: {
          title: 'CareerBuddy Blog: Career Insights & Professional Growth',
          description:
            'Discover expert career advice, industry insights, and professional development resources on the CareerBuddy blog. Stay ahead in your career journey with our curated content.',
        },
      },
      {
        path: '/blog/:slug',
        name: 'SingleBlog',
        component: () => import(/* webpackChunkName: "single-blog" */ '../views/SingleBlog.vue'),
        meta: {
          title: 'CareerBuddy Blog',
          description: 'Read our detailed insights and articles about career growth and professional development.',
        },
      },
      {
        path: '/referral',
        name: 'Referral',
        component: () => import(/* webpackChunkName: "referral" */ '../views/landing/Referral.vue'),
        meta: {
          title: 'CareerBuddy: Make Cool Cash by Referring Top Talent',
          description:
            'Join CareerBuddy Talent Connect and earn up to NGN 200k for every successful talent referral. Perfect for recruiters and industry experts with strong professional networks.',
        },
      },
    ],
  },
];
